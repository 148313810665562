.home { 
    display: flex;
    flex-direction: column;
}

.home__header {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    align-items: center;
}

.home__header a {
    margin-right: 20px;
    text-decoration: inherit;
    color: rgba(0, 0, 0, .87);
    font-size: 15px;
}

.home__header a:hover{
    text-decoration: underline;
}

.home__headerRight {
    display: flex;
    align-items: center;
    min-width: 13vw;
    justify-content: space-between;
}

.home__headerRight > .MuiAvatar-root {
    margin: 0 20px;
}

.home__body {
    flex: 1;
    display: flex;
    margin-top: 10%;
    flex-direction: column;
}

.home__body > img {
    object-fit: contain;
    height: 125px;
}
.search__input {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    height: 50px;
    padding: 10px 20px;
    border-radius: 50px;
    width: 75vw;
    max-width: 560px;
    margin: 0 auto;
    margin-top: 40px;
    
}

.search__input > input {
    flex: 1;
    padding: 10px 20px;
    font-size: medium;
    border: none;
    background-color: transparent;
}

.search__input > input:focus {
    outline-width: 0;
}

.search__inputIcon {
    color: gray;
}

.search__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.search__buttons button {
    margin: 5px;
    padding: 7px 15px;
    background-color: #f8f8f8;
    border: 1px solid #FFF;
    text-transform: inherit;
    color: #5f6368;
}

.search__buttons button:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
    background-color: #f8f8f8;
    border: 1px solid #c6c6c6;
    color: #222;
}

.search__buttonHidden {
    display: none !important;
}