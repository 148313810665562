.cookie-consent-popup {
    position: fixed;
    bottom: 20px;
    right: 0px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }
  
  .cookie-consent-popup.hidden {
    display: none;
  }

  .item{
    font-size: 14px;
    text-align: center;
  }
.sidebar .nav-item{
  width: 100% !important;
}

@media screen and (max-width: 768px) {
  #link-Cookies{
    display: block !important;
  }
  .btn{
    margin-bottom: 5px;
  }

}