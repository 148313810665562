@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "volt/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "volt/vendor";

// volt mixins & functions
@import "volt/mixins";
@import "volt/functions";

// Utilities
@import "volt/reboot";
@import "volt/utilities";

// Layout
@import "volt/layout";

// Components
@import "volt/components";
@import "volt/base1";
//@import "volt/base2";
@import "volt/base3";


// write your custom styles here!
@import "../pages/css/style.css";
@import "../pages/css/bootstrap.min.css";
@import "../pages/css/typography.css";
@import "../pages/css/responsive.css";


