
.react-switch {
    vertical-align: middle;
    margin-left: 4px;
  }

  .img-wrapper{
    overflow:hidden;
  }
  img.hover-zoom:hover {
    transform: scale(1.25);
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  .full-width-modal .modal-dialog {
    max-width: 100%;
    margin: 0;
  }
  
  .full-width-modal .modal-content {
    border-radius: 0;
  }
  .drop-zone-area {
    height: 200px;
}