.noPadding{
    padding: 0px;
}
.noMargin{
    margin: 0px;
}
.item{
    height: 25px;
    a{
        color :#958f8f !important;
    }
}
.link:hover{
cursor: pointer;
}
#identity{
    display: none;
}
#footerContainer{
    display: flex !important;

}
footer{
    a{
        border-radius: 0px !important;
        height: auto !important;
    }
}
@media screen and (max-width: 768px) {
    #identity{
        display: block;
    }
    #footerContainer{
        display: block !important;

    }
    
}
.positionfooter{
    padding: 0;
    margin: 0;
    flex-direction: row-reverse;
    position:relative;
    bottom:0;
    right:0;
    width:100%;
}

.centerFooter{
    width: 100%;
    flex-direction: row-reverse;
    justify-content: center !important;
}