.metallic-plate {
    position: relative;
    background: linear-gradient(to right, #636363, #7b7b7b);
    border: 10px solid #bdc3c7;
    border-radius: 15px;
    padding: 20px;
    color: #ecf0f1;
    font-family: 'Arial', sans-serif;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
    width: "100%"; /* Increased width */
    height: "80%";
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.qr-code-container {
    max-width: 100%;
    max-height: 100%;
}
.qr-code {
    width: 100%;
    height: 100%;
}
.plate-info {
    flex-grow: 1;
    text-align: left;
    padding-left: 32px;
}
.plate-text {
    margin: 0;
    font-weight: bold;
    font-size: 42px;
    transform: translateX(20%);  
}
.plate-number {
    font-size: 48px;
}
.plate-date {
    font-size: 24px;
    margin-top: 120px;
    transform: translateX(30%);  
}