.dropdown-menu.show{
    left: initial  ;
    right: 0 !important;
    display: block !important;
}
.dropdown-menu{
    display: none !important;
}
#input-group-dropdown-2{
    padding: 0 !important;
}


@media screen and (max-width: 768px) {
    .dropdown-item.dropdown-item{
        color: #2e3650;
    }    
}