.langContainer{
    float: right;
    height: 30px;
    align-content: center;
}
.noPadding{
    padding: 0px;
}
@media screen and (max-width: 768px) {
    #navbar{
        display: none;
    }
    
}