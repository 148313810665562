
aside.context {
    text-align: center;
    color: #fff;
    line-height: 1.7;
}
aside.context a {
    text-decoration: none;
    color: #fff;
    padding: 3px 0;
    border-bottom: 1px dashed;
}
aside.context .explanation {
    max-width: 700px;
    margin: 4em auto 0;
}
footer {
    text-align: center;
    margin: 2em auto;
    width: 100%;
}
footer a {
    text-decoration: none;
    display: inline-block;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: transparent;
    border: 1px dashed #fff;
    color: #fff;
    margin: 5px;
}
footer a:hover {
    background: rgba(255, 255, 255, 0.1);
}
footer a .icons {
    margin-top: 12px;
    display: inline-block;
    font-size: 20px;
}
.periodic-table {
    display: grid;
    overflow: scroll;
    grid-template-columns: repeat(9, 100px);
    grid-template-rows: repeat(4, 100px);
    grid-gap: 10px;
    margin: auto;
    max-width: 1200px;
    justify-content: center;
}
.periodic-element {
    padding: 4px;
    width: 100px;
    position: relative; 
    z-index: 1;
    cursor: default;
    transition: all 0.3s ease;
}
.periodic-element .periodic-element-inner {
    background: transparent;
    padding: 10px 15px;
    width: calc(100% - 30px);
    height: calc(100% - 20px);
    transition: inherit;
}
.periodic-element .title {
    font: 700 26.1538461538px "Poppins", sans-serif;
    margin: 0.3em 0 0;
    transition: 0.8s ease 600ms;
}
.periodic-element .description {
    font: 500 12px "Poppins", sans-serif;
    margin-top: -0.2em;
}
.periodic-element:after {
    z-index: 10;
    content: attr(data-description);
    background: #f9f8f7;
    color: #333;
    position: absolute;
    top: 80%;
    opacity: 0;
    transition: opacity 0.3s ease 0.3s, top 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
    height: auto;
    font-size: 12px;
    line-height: 1.4;
    padding: 10px;
    margin: 0 0 0 0 ;
    border-radius: 6px;
    text-align: center;
}
.periodic-element:hover {
    transform: scale(1.12);
    z-index: 10;
}
.periodic-element:hover .periodic-element-inner {
    background: transparent;
}
.periodic-element:hover .title, .periodic-element:hover .description {
    -webkit-text-fill-color: #222;
}
.periodic-element:hover:after {
    top: 105%;
    opacity: 1;
}
a.periodic-element {
    cursor: pointer;
}
.social-media {
    background: linear-gradient(to bottom right, #f3f9a6 0%, #cbc634 100%);
}
.social-media .title, .social-media .description {
    background: -webkit-linear-gradient(#f3f9a6, #cbc634);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.fun-stuff {
    background: linear-gradient(to bottom right, #ff616d 0%, #ffc171 100%);
}
.fun-stuff .title, .fun-stuff .description {
    background: -webkit-linear-gradient(#ff616d, #ffc171);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.just-trying {
    background: linear-gradient(to bottom right, #37cfdc 0%, #5a88e5 100%);
}
.just-trying .title, .just-trying .description {
    background: -webkit-linear-gradient(#37cfdc, #5a88e5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.intense-work {
    background: linear-gradient(to bottom right, #58ac30 0%, #a7df62 100%);
}
.intense-work .title, .intense-work .description {
    background: -webkit-linear-gradient(#58ac30, #a7df62);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.legend-table {
    padding: 15px;
    text-align: center;
    font-size: 15px;
    margin: 2em auto 0;
}
.legend-table__marker {
    width: 25px;
    height: 25px;
    margin: 5px 5px 10px;
    display: inline-block;
    vertical-align: middle;
    box-shadow: 0.5px 1px rgba(0, 0, 0, 0.15);
}
.legend-table__text {
    display: inline-block;
    vertical-align: middle;
    margin: 0 25px 5px 5px;
    background: -webkit-linear-gradient(#ccc, #eee);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.legend-table__direction {
    display: inline-block;
    margin: 10px 15px 10px 10px;
    background: -webkit-linear-gradient(#ccc, #eee);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.empty-spacer-1 {
    grid-column: 3 / span 4;
    grid-row: 1;
}
.empty-spacer-2 {
    grid-column: 1;
    grid-row: 4;
}
